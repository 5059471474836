body {
  margin: 0;
  overflow: hidden;
}

.sc-hKFxyN {
    z-index: 1029 !important;
  }

/* TODO: work around for NEF bug */
.rel-box input {
  width: 100%;
}

a {
  cursor: pointer !important;
}

@keyframes whiteToBlack {
  from {
    color: white;
  }
  to {
    color: black;
  }
}
